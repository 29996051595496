import { useState } from 'react';
import { Header as PrimerHeader, Button, Text, Box, ActionMenu, ActionList, Avatar } from '@primer/react';
import { PlusIcon } from '@primer/octicons-react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogoTextShape } from '../assets';
import { collection, addDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { sampleInvoice } from '../templates/sampleInvoice';
import { sampleTemplateData } from '../templates/sampleInvoice';
import { extractVariablesFromJson } from '../utils/jsonUtils';

export function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleNewApiKey = async () => {
    try {
      const userRef = doc(db, 'users', user!.uid);
      const api_key = crypto.randomUUID().replace(/-/g, '');
      await addDoc(collection(db, 'api_keys'), {
        api_key,
        user_ref: userRef,
        created_at: new Date()
      });

      if (location.pathname === '/dashboard/api-keys') {
        navigate(0);
      } else {
        navigate('/dashboard/api-keys');
      }
    } catch (err) {
      console.error('Error generating API key:', err);
    }
  };

  const handleNewTemplate = async () => {
    try {
      setLoading(true);
      const userRef = doc(db, 'users', user!.uid);
      
      const variables = extractVariablesFromJson(sampleTemplateData);
      
      const newTemplate = {
        name: 'New Template',
        html: sampleInvoice,
        data_json: JSON.stringify(sampleTemplateData, null, 2),
        variables: variables,
        config: {
          margine_bottom: 4,
          margine_left: 4,
          margine_right: 4,
          margine_top: 4,
          orientation: 'Landscape',
          paper_size: 'Letter',
          print_background: true
        },
        user_ref: userRef,
        created_at: new Date()
      };

      const docRef = await addDoc(collection(db, 'templates'), newTemplate);
      navigate(`/dashboard/templates/edit/${docRef.id}`);
    } catch (err) {
      console.error('Error creating new template:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimerHeader sx={{ 
      width: '100%', 
      px: 4, 
      bg: 'canvas.default', 
      borderBottom: '1px solid', 
      borderColor: 'border.default',
      position: 'relative',
      zIndex: 1
    }}>
      {/* Logo */}
      <PrimerHeader.Item>
        <img 
          src={LogoTextShape} 
          alt="Forge PDF" 
          style={{ height: '32px' }}
          onClick={() => navigate('/dashboard')}
          role="button"
          className="cursor-pointer"
        />
      </PrimerHeader.Item>

      <PrimerHeader.Item full />

      {/* Create New Button */}
      <PrimerHeader.Item>
        <ActionMenu>
          <ActionMenu.Anchor>
            <Button 
              leadingVisual={PlusIcon}
              sx={{ mr: 2 }}
            >
              New
            </Button>
          </ActionMenu.Anchor>
          <ActionMenu.Overlay 
            sx={{ 
              position: 'absolute',
              zIndex: 100,
              boxShadow: 'shadow.large',
              border: '1px solid',
              borderColor: 'border.default',
              borderRadius: 2
            }}
          >
            <ActionList>
              <ActionList.Item onSelect={handleNewTemplate}>
                New Template
              </ActionList.Item>
              <ActionList.Item onSelect={handleNewApiKey}>
                New API Key
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </PrimerHeader.Item>

      {/* User Menu */}
      <PrimerHeader.Item>
        <ActionMenu>
          <ActionMenu.Anchor>
            <Avatar 
              src={user?.photoURL || "https://avatars.githubusercontent.com/u/default"}
              alt={user?.email || 'User avatar'}
              size={34}
              sx={{ cursor: 'pointer' }}
            />
          </ActionMenu.Anchor>
          <ActionMenu.Overlay 
            sx={{ 
              position: 'absolute',
              zIndex: 100,
              boxShadow: 'shadow.large',
              border: '1px solid',
              borderColor: 'border.default',
              borderRadius: 2
            }}
          >
            <ActionList>
              <ActionList.Item>
                Signed in as <br />
                <Text sx={{ fontWeight: 'bold' }}>{user?.email}</Text>
              </ActionList.Item>
              <ActionList.Divider />
              <ActionList.Item>Your Profile</ActionList.Item>
              <ActionList.Item onSelect={() => navigate('/dashboard/settings')}>
                Settings
              </ActionList.Item>
              <ActionList.Divider />
              <ActionList.Item variant="danger" onClick={handleLogout}>
                Sign out
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </PrimerHeader.Item>
    </PrimerHeader>
  );
} 