import { useState, useEffect } from 'react';
import { Button, Box, Text, Flash, Tooltip, Spinner } from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { KeyIcon, PlusIcon, CopyIcon, TrashIcon, HistoryIcon } from '@primer/octicons-react';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { DashboardLayout } from '../components/DashboardLayout';
import { Toast } from '../components/Toast';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface ApiKey {
  id: string;
  api_key: string;
  created_at: Date;
  user_ref: any;
}

export default function ApiKeys() {
  const { user, logout } = useAuth();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteKeyId, setDeleteKeyId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const loadApiKeys = async () => {
    try {
      const userRef = doc(db, 'users', user!.uid);
      const q = query(
        collection(db, 'api_keys'),
        where('user_ref', '==', userRef)
      );
      const querySnapshot = await getDocs(q);
      const keys = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          api_key: data.api_key || '',
          created_at: data.created_at?.toDate() || new Date(),
          user_ref: data.user_ref
        };
      });
      setApiKeys(keys);
    } catch (err) {
      console.error('Error loading API keys:', err);
      setError('Failed to load API keys');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadApiKeys();
  }, [user]);

  const generateApiKey = async () => {
    try {
      const userRef = doc(db, 'users', user!.uid);
      const api_key = crypto.randomUUID().replace(/-/g, '');
      await addDoc(collection(db, 'api_keys'), {
        api_key,
        user_ref: userRef,
        created_at: new Date()
      });
      await loadApiKeys();
    } catch (err) {
      console.error('Error generating API key:', err);
      setError('Failed to generate API key');
    }
  };

  const copyApiKey = async (apiKey: string) => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (err) {
      console.error('Error copying API key:', err);
      setError('Failed to copy API key');
    }
  };

  const handleDeleteClick = (keyId: string) => {
    setDeleteKeyId(keyId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!deleteKeyId) return;
    
    try {
      await deleteDoc(doc(db, 'api_keys', deleteKeyId));
      await loadApiKeys();
      setIsDeleteDialogOpen(false);
      setDeleteKeyId(null);
    } catch (err) {
      console.error('Error deleting API key:', err);
      setError('Failed to delete API key');
    }
  };

  const maskApiKey = (key: string | undefined) => {
    if (!key) return '••••••••••••••••••••••••••••••••';
    const visiblePart = key.slice(0, 8);
    const hiddenPart = '•'.repeat(24);
    return `${visiblePart}${hiddenPart}`;
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: '20px' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3 
        }}>
          <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold' }}>API Keys</Text>
          <Button 
            onClick={generateApiKey} 
            leadingVisual={PlusIcon}
            disabled={loading}
          >
            Generate New Key
          </Button>
        </Box>

        {error && (
          <Flash variant="danger" sx={{ mb: 3 }}>
            {error}
          </Flash>
        )}

        <Box sx={{ 
          height: 'calc(100vh - 84px)',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center',
              flex: 1,
              gap: 3
            }}>
              <Spinner size="large" />
              <Text sx={{ color: 'fg.muted' }}>Loading API keys...</Text>
            </Box>
          ) : apiKeys.length === 0 ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}>
              <Text>No API keys found. Generate one to get started.</Text>
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'auto',
              width: '100%',
              py: '20px',
              pb: '80px'
            }}>
              <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                {apiKeys.map((apiKey) => (
                  <Box 
                    key={apiKey.id}
                    onClick={() => navigate(`/dashboard/api-usage/${apiKey.id}`)}
                    sx={{ 
                      p: 3,
                      bg: 'canvas.subtle',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      border: '1px solid',
                      borderColor: 'border.default',
                      mb: 2,
                      '&:hover': {
                        borderColor: 'accent.emphasis',
                        boxShadow: 'shadow.medium',
                      },
                      '&:last-child': {
                        mb: 0
                      }
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: 'bold',
                            color: 'fg.default',
                            fontFamily: 'mono',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {maskApiKey(apiKey.api_key)}
                        </Text>
                      </Box>
                      <Text
                        sx={{
                          fontSize: 0,
                          color: 'fg.muted'
                        }}
                      >
                        Created {formatDistanceToNow(apiKey.created_at)} ago
                      </Text>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip text="View usage logs" direction="n">
                        <Button
                          variant="invisible"
                          size="small"
                          sx={{ 
                            padding: '4px',
                            '&:hover': {
                              bg: 'accent.subtle'
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/dashboard/api-usage/${apiKey.id}`);
                          }}
                          leadingVisual={HistoryIcon}
                        />
                      </Tooltip>
                      <Tooltip text="Copy API key" direction="n">
                        <Button
                          variant="invisible"
                          size="small"
                          sx={{ 
                            padding: '4px',
                            '&:hover': {
                              bg: 'accent.subtle'
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            apiKey.api_key && copyApiKey(apiKey.api_key);
                          }}
                          leadingVisual={CopyIcon}
                        />
                      </Tooltip>
                      <Tooltip text="Delete API key" direction="n">
                        <Button
                          variant="invisible"
                          size="small"
                          sx={{ 
                            color: 'danger.fg',
                            padding: '4px',
                            '&:hover': {
                              bg: 'danger.subtle'
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(apiKey.id);
                          }}
                          leadingVisual={TrashIcon}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {showToast && (
        <Toast message="API key copied to clipboard!" />
      )}

      {isDeleteDialogOpen && (
        <Dialog
          title="Delete API Key"
          onClose={() => setIsDeleteDialogOpen(false)}
          width="medium"
          footerButtons={[
            {
              content: 'Cancel',
              onClick: () => setIsDeleteDialogOpen(false)
            },
            {
              content: 'Delete',
              onClick: confirmDelete,
              variant: 'danger'
            }
          ]}
        >
          <Text>Are you sure you want to delete this API key? This action cannot be undone.</Text>
        </Dialog>
      )}
    </DashboardLayout>
  );
} 