import { useState, useRef, createRef, useEffect } from 'react';
import { Box, Button, Text, ActionMenu, TextInput, ActionList, UnderlineNav, FormControl, ActionBar } from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { DashboardLayout } from '../components/DashboardLayout';
import { PlusIcon, CalendarIcon, TagIcon, HashIcon, DeviceCameraIcon, ArrowLeftIcon, EyeIcon, XIcon, TypographyIcon, ImageIcon } from '@primer/octicons-react';
import AceEditor from 'react-ace';
import { Ace } from 'ace-builds';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Toast } from '../components/Toast';
import { useNavigate } from 'react-router-dom';
import { JSONPath } from 'jsonpath-plus';
import { sampleInvoice } from '../templates/sampleInvoice';
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Add these constants at the top of the file, outside the component
const PAPER_SIZES = {
  'A1': {
    width: '594mm',
    height: '841mm'
  },
  'A2': {
    width: '420mm',
    height: '594mm'
  },
  'A3': {
    width: '297mm',
    height: '420mm'
  },
  'A4': {
    width: '210mm',
    height: '297mm'
  },
  'A5': {
    width: '148mm',
    height: '210mm'
  },
  'A6': {
    width: '105mm',
    height: '148mm'
  },
  'Letter': {
    width: '215.9mm',
    height: '279.4mm'
  },
  'Legal': {
    width: '215.9mm',
    height: '355.6mm'
  },
  'Tabloid': {
    width: '279.4mm',
    height: '431.8mm'
  },
  'Ledger': {
    width: '431.8mm',
    height: '279.4mm'
  }
};

// Add this helper function to replace variables with values
const replaceVariablesWithValues = (content: string, jsonData: string): string => {
  try {
    const data = JSON.parse(jsonData);
    
    // Function to get nested object value using dot notation
    const getNestedValue = (obj: any, path: string) => {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : undefined;
      }, obj);
    };

    // Replace all {{variable}} occurrences with their values
    return content.replace(/\{\{([^}]+)\}\}/g, (match, variable) => {
      // Remove any whitespace
      const cleanVariable = variable.trim();
      
      // Check if it's a formatted variable (date, currency, etc.)
      const formatMatch = cleanVariable.match(/^(date|currency|barcode|qrcode)\s+(.+)$/);
      
      if (formatMatch) {
        const [, format, varName] = formatMatch;
        const value = getNestedValue(data, varName);
        
        if (value !== undefined) {
          switch (format) {
            case 'date':
              return new Date(value).toLocaleDateString();
            case 'currency':
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(value);
            case 'barcode':
            case 'qrcode':
              return value; // You might want to add actual barcode/QR code generation here
            default:
              return value;
          }
        }
      } else {
        const value = getNestedValue(data, cleanVariable);
        return value !== undefined ? value : match;
      }
      
      return match; // Keep original if no value found
    });
  } catch (error) {
    console.error('Error replacing variables:', error);
    return content;
  }
};

// Add this type definition
interface ApiKey {
  id: string;
  api_key: string;
}

// Add these helper functions after the existing interfaces
const formatCSS = (css: string): string => {
  // Remove all newlines and extra spaces
  const minified = css.replace(/\s+/g, ' ').trim();
  
  // Split by closing brace and format each rule
  return minified.split('}').map(rule => {
    if (!rule.trim()) return '';
    
    // Split selector and properties
    const [selector, properties] = rule.split('{');
    if (!properties) return '';
    
    // Format properties to be on one line
    const formattedProperties = properties.split(';')
      .map(prop => prop.trim())
      .filter(prop => prop)
      .join('; ');
    
    return `${selector.trim()} { ${formattedProperties} }`;
  }).filter(rule => rule).join('\n');
};

const formatHTMLContent = (html: string): string => {
  // First, handle the style tag content
  html = html.replace(/<style>([\s\S]*?)<\/style>/g, (match, styleContent) => {
    const formattedCSS = formatCSS(styleContent);
    return `<style>\n${formattedCSS}\n</style>`;
  });

  // Then format the HTML structure
  let formatted = '';
  let indent = 0;
  let lastTag = '';
  
  // Split by tags but preserve whitespace within tags
  const parts = html.split(/(<[^>]+>)/);
  
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (!part.trim()) continue;
    
    if (part.startsWith('</')) {
      // Closing tag
      indent--;
      const content = parts[i - 1]?.trim();
      if (content && !content.startsWith('<') && content.length < 50) {
        // Short content - keep it on one line with its tags
        formatted = formatted.slice(0, -1); // Remove the last newline
        formatted += part + '\n';
      } else {
        formatted += '  '.repeat(Math.max(0, indent)) + part + '\n';
      }
    } else if (part.startsWith('<')) {
      // Opening tag
      formatted += '  '.repeat(indent) + part + '\n';
      if (!part.startsWith('<br') && 
          !part.startsWith('<img') && 
          !part.startsWith('<input') && 
          !part.endsWith('/>')) {
        indent++;
      }
      lastTag = part;
    } else {
      // Text content
      const nextTag = parts[i + 1];
      if (nextTag && part.length < 50 && !part.includes('\n')) {
        // Short content - keep it on the same line
        formatted = formatted.slice(0, -1); // Remove the last newline
        formatted += part;
      } else {
        formatted += '  '.repeat(indent) + part + '\n';
      }
    }
  }
  
  return formatted;
};

// Add this function to handle image upload
const uploadImage = async (file: File): Promise<string> => {
  const storageRef = ref(storage, `template-images/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

export default function TemplateEditor() {
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [newVariable, setNewVariable] = useState('');
  const editorRef = useRef<Ace.Editor | null>(null);
  const [selectedVariable, setSelectedVariable] = useState<string | null>(null);
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [templateName, setTemplateName] = useState('New Template');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('editor');
  const [config, setConfig] = useState({
    margine_bottom: 4,
    margine_left: 4,
    margine_right: 4,
    margine_top: 4,
    orientation: "Portrait",
    paper_size: "A4",
    print_background: true
  });
  const [jsonData, setJsonData] = useState<string>('');
  const [variables, setVariables] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(true);
  const [previewHTML, setPreviewHTML] = useState('');
  const { user } = useAuth();
  const [isTestingTemplate, setIsTestingTemplate] = useState(false);
  const [testResult, setTestResult] = useState<{
    success: boolean;
    message: string;
    pdfUrl?: string;
  } | null>(null);

  useEffect(() => {
    const loadTemplate = async () => {
      if (!id) return;

      try {
        const templateDoc = await getDoc(doc(db, 'templates', id));
        if (templateDoc.exists()) {
          const templateData = templateDoc.data();
          const formattedHtml = formatHTMLContent(templateData.html || sampleInvoice);
          setHtmlContent(formattedHtml);
          setTemplateName(templateData.name);
          if (templateData.config) {
            setConfig(templateData.config);
          }
          if (templateData.data_json) {
            setJsonData(templateData.data_json);
          }
          if (templateData.variables) {
            setVariables(templateData.variables);
          }
        } else {
          setHtmlContent(formatHTMLContent(sampleInvoice));
        }
      } catch (err) {
        console.error('Error loading template:', err);
        setError('Failed to load template');
      } finally {
        setLoading(false);
      }
    };

    loadTemplate();
  }, [id]);

  useEffect(() => {
    const paperSize = PAPER_SIZES[config.paper_size as keyof typeof PAPER_SIZES];
    const isLandscape = config.orientation === 'Landscape';
    
    // Replace variables in the HTML content with actual values
    const contentWithValues = jsonData ? replaceVariablesWithValues(htmlContent, jsonData) : htmlContent;
    
    const previewStyles = `
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      html, body {
        width: 100%;
        height: 100%;
        background: #e0e0e0;
      }
      .preview-container {
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow: auto;
      }
      .paper-wrapper {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .paper {
        background: white;
        width: ${isLandscape ? paperSize.height : paperSize.width};
        height: ${isLandscape ? paperSize.width : paperSize.height};
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        transform-origin: center;
        transform: scale(calc(min(
          (100vh - 40px) / ${isLandscape ? paperSize.width : paperSize.height},
          (100vw - 40px) / ${isLandscape ? paperSize.height : paperSize.width}
        ) * 0.8));
      }
      .content {
        position: absolute;
        top: ${config.margine_top}mm;
        right: ${config.margine_right}mm;
        bottom: ${config.margine_bottom}mm;
        left: ${config.margine_left}mm;
        overflow: auto;
      }
      .margin-indicator {
        position: absolute;
        top: ${config.margine_top}mm;
        right: ${config.margine_right}mm;
        bottom: ${config.margine_bottom}mm;
        left: ${config.margine_left}mm;
        border: 1px dashed #ccc;
        pointer-events: none;
      }
      @media print {
        html, body { background: none; }
        .preview-container { padding: 0; overflow: visible; }
        .paper {
          box-shadow: none;
          margin: 0;
          transform: none;
        }
        .margin-indicator { display: none; }
      }
    `;

    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>${previewStyles}</style>
        </head>
        <body>
          <div class="preview-container">
            <div class="paper-wrapper">
              <div class="paper">
                <div class="margin-indicator"></div>
                <div class="content">
                  ${contentWithValues}
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    `;
    
    setPreviewHTML(html);
  }, [config, htmlContent, jsonData]);

  const handleEditorLoad = (editor: Ace.Editor) => {
    editorRef.current = editor;

    // Function to highlight all variables
    const highlightVariables = () => {
      // Clear existing markers
      const markers = editor.session.getMarkers(false);
      if (markers) {
        Object.keys(markers).forEach(id => {
          editor.session.removeMarker(Number(id));
        });
      }

      // Find and highlight all content inside {{}}
      const lines = editor.session.doc.getAllLines();
      
      lines.forEach((line: string, row: number) => {
        let startIndex = 0;
        while (true) {
          // Find opening {{
          const start = line.indexOf('{{', startIndex);
          if (start === -1) break;
          
          // Find closing }}
          const end = line.indexOf('}}', start);
          if (end === -1) break;
          
          // Add marker for the entire {{...}} content
          editor.session.addMarker(
            new ace.Range(row, start, row, end + 2),
            'ace_variable_highlight',
            'text',
            false
          );
          
          startIndex = end + 2;
        }
      });
    };

    // Add custom CSS for variable highlighting
    const style = document.createElement('style');
    style.textContent = `
      .ace_variable_highlight {
        position: absolute;
        background-color: #fff8c5 !important;
        border-radius: 2px;
      }
    `;
    document.head.appendChild(style);

    // Initial highlight
    highlightVariables();

    // Highlight variables on content change
    editor.session.on('change', highlightVariables);

    // Add click handler
    editor.container.addEventListener('click', (e: MouseEvent) => {
      const position = editor.getCursorPosition();
      const line = editor.session.getLine(position.row);
      const currentColumn = position.column;
      
      let start = currentColumn;
      let end = currentColumn;
      
      while (start >= 0 && line.substring(start - 2, start) !== '{{') start--;
      while (end < line.length && line.substring(end, end + 2) !== '}}') end++;
      
      if (start >= 2 && end < line.length && line.substring(start - 2, start) === '{{' && line.substring(end, end + 2) === '}}') {
        const variable = line.substring(start, end);
        setSelectedVariable(variable);
        setMenuPosition({ top: e.clientY, left: e.clientX });
      } else {
        setSelectedVariable(null);
        setMenuPosition(null);
      }
    });
  };

  const applyVariableFormat = (format: string) => {
    if (!selectedVariable || !editorRef.current) return;
    
    const editor = editorRef.current;
    const position = editor.getCursorPosition();
    const line = editor.session.getLine(position.row);
    
    let start = position.column;
    while (start >= 0 && line.substring(start - 2, start) !== '{{') start--;
    
    let end = position.column;
    while (end < line.length && line.substring(end, end + 2) !== '}}') end++;
    
    if (start >= 2 && end < line.length) {
      const range = new ace.Range(position.row, start - 2, position.row, end + 2);
      
      let newValue = '';
      switch (format) {
        case 'date':
          newValue = `{{date ${selectedVariable}}}`;
          break;
        case 'currency':
          newValue = `{{currency ${selectedVariable}}}`;
          break;
        case 'barcode':
          newValue = `{{barcode ${selectedVariable}}}`;
          break;
        case 'qrcode':
          newValue = `{{qrcode ${selectedVariable}}}`;
          break;
      }
      
      editor.session.replace(range, newValue);
    }
    
    setSelectedVariable(null);
    setMenuPosition(null);
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      await updateDoc(doc(db, 'templates', id), {
        html: htmlContent,
        name: templateName,
        config: config,
        data_json: jsonData,
        variables: variables,
        updated_at: Timestamp.now()
      });
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (err) {
      console.error('Error saving template:', err);
      setError('Failed to save template');
    }
  };

  const extractJsonPaths = (jsonString: string) => {
    try {
      const jsonObj = JSON.parse(jsonString);
      console.log('Parsed JSON:', jsonObj);

      // Function to recursively get all paths
      const getPaths = (obj: any, prefix: string = ''): string[] => {
        let paths: string[] = [];
        
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const newPath = prefix ? `${prefix}.${key}` : key;
            
            // Add the current path
            paths.push(newPath);
            
            // If value is an object and not null, recurse
            if (value && typeof value === 'object' && !Array.isArray(value)) {
              paths = paths.concat(getPaths(value, newPath));
            }
          }
        }
        
        return paths;
      };

      // Get all paths
      const allPaths = getPaths(jsonObj);
      console.log('All paths:', allPaths);
      
      setVariables(allPaths);
      
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setError('Invalid JSON format');
    }
  };

  const handleTestTemplate = async () => {
    setIsTestingTemplate(true);
    setTestResult(null);
    
    try {
      // Check if JSON data exists
      if (!jsonData.trim()) {
        setTestResult({
          success: false,
          message: 'Please provide sample data in the Data Mapping tab before testing'
        });
        return;
      }

      // Validate JSON data
      try {
        JSON.parse(jsonData);
      } catch (e) {
        setTestResult({
          success: false,
          message: 'Invalid JSON data format'
        });
        return;
      }

      // Check for API key
      const apiKeysRef = collection(db, 'api_keys');
      const apiKeysQuery = query(
        apiKeysRef,
        where('user_ref', '==', doc(db, 'users', user!.uid))
      );
      
      const apiKeysSnapshot = await getDocs(apiKeysQuery);
      
      if (apiKeysSnapshot.empty) {
        setTestResult({
          success: false,
          message: 'You need to create an API key first'
        });
        return;
      }

      // Save the template first
      if (!id) return;
      
      try {
        await updateDoc(doc(db, 'templates', id), {
          html: htmlContent,
          name: templateName,
          config: config,
          data_json: jsonData,
          variables: variables,
          updated_at: Timestamp.now()
        });
      } catch (err) {
        setTestResult({
          success: false,
          message: 'Failed to save template before testing'
        });
        return;
      }

      // Get the first API key and proceed with API call
      const apiKey = apiKeysSnapshot.docs[0].data().api_key;

      // Make the API call
      const response = await fetch('https://app.forgepdf.io/api/pdf', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          template_id: id,
          data: JSON.parse(jsonData)
        })
      });

      // Handle the response
      if (response.ok) {
        // Convert the PDF blob to a URL
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        
        setTestResult({
          success: true,
          message: 'PDF generated successfully!',
          pdfUrl
        });
      } else {
        const errorText = await response.text();
        setTestResult({
          success: false,
          message: `Error: ${errorText}`
        });
      }
    } catch (error) {
      setTestResult({
        success: false,
        message: `Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`
      });
    } finally {
      setIsTestingTemplate(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'editor':
        return (
          <Box sx={{ 
            flex: 1, 
            p: 3,
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Box sx={{ 
              mb: 3,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}>
              <Text as="h2" sx={{ mb: 2 }}>Template Editor</Text>
              <Box sx={{ flex: 1 }} />
              
              <ActionBar aria-label="Template editor actions" size="medium">
                {/* Format HTML Action */}
                <ActionBar.IconButton
                  icon={TypographyIcon}
                  aria-label="Format HTML"
                  onClick={() => {
                    if (editorRef.current) {
                      const formatted = formatHTMLContent(editorRef.current.getValue());
                      editorRef.current.setValue(formatted, -1);
                    }
                  }}
                  tooltipDirection="s"
                />

                {/* Upload Image Action */}
                <ActionBar.IconButton
                  icon={ImageIcon}
                  aria-label="Upload Image"
                  tooltipDirection="s"
                  onClick={() => {
                    document.getElementById('image-upload')?.click();
                  }}
                />
                <input
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      try {
                        const imageUrl = await uploadImage(file);
                        if (editorRef.current) {
                          const position = editorRef.current.getCursorPosition();
                          const imageTag = `<img src="${imageUrl}" alt="${file.name}" style="max-width: 100%;" />`;
                          editorRef.current.session.insert(position, imageTag);
                        }
                      } catch (error) {
                        console.error('Error uploading image:', error);
                        setError('Failed to upload image');
                      }
                    }
                  }}
                />

                {/* Add Variable Action */}
                <ActionBar.IconButton
                  icon={PlusIcon}
                  aria-label="Add Variable"
                  onClick={() => setIsVariableModalOpen(true)}
                  tooltipDirection="s"
                />
              </ActionBar>
            </Box>
            
            <Box sx={{ flex: 1, overflow: 'hidden' }}>
              <AceEditor
                mode="html"
                theme="github"
                onChange={setHtmlContent}
                value={htmlContent}
                name="html-editor"
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                style={{ width: '100%', height: '100%' }}
                onLoad={handleEditorLoad}
              />
            </Box>
          </Box>
        );
      case 'mapping':
        return (
          <Box sx={{ 
            p: 4, 
            height: '100%', 
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="primary"
                size="medium"
                disabled={!jsonData.trim()}
                onClick={() => extractJsonPaths(jsonData)}
              >
                Map JSON Data To Variables
              </Button>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              gap: 3, 
              height: 'calc(100% - 60px)'
            }}>
              {/* Left side - JSON input */}
              <Box sx={{ 
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Text as="h3" sx={{ mb: 2 }}>JSON Data</Text>
                <Box 
                  as="textarea"
                  value={jsonData}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setJsonData(e.target.value)}
                  sx={{ 
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    fontFamily: 'monospace',
                    resize: 'none',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'border.default',
                    borderRadius: 2,
                    fontSize: 1,
                    lineHeight: 1.4,
                    outline: 'none',
                    '&:focus': {
                      borderColor: 'accent.emphasis',
                      boxShadow: 'inset 0 0 0 1px accent.emphasis',
                    }
                  }}
                  placeholder="Paste your JSON data here..."
                />
              </Box>

              {/* Right side - Variables list */}
              <Box sx={{ 
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Text as="h3" sx={{ mb: 2 }}>Mapped Variables</Text>
                <Box sx={{ 
                  flex: 1,
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  p: 3,
                  overflow: 'auto'
                }}>
                  {variables.length > 0 ? (
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 2 
                    }}>
                      {variables.map((variable, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            px: 2,
                            py: 1,
                            borderRadius: '2em',
                            bg: 'accent.subtle',
                            color: 'accent.fg',
                            fontSize: 1,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            '&:hover': {
                              bg: 'accent.muted'
                            }
                          }}
                        >
                          {variable}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ 
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'fg.muted'
                    }}>
                      Start map your data to variables
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case 'settings':
        return (
          <Box sx={{ p: 4, height: '100%', overflow: 'auto' }}>
            <Text as="h2" sx={{ mb: 3 }}>Document Settings</Text>
            
            {/* Margins Section */}
            <Box sx={{ mb: 4 }}>
              <Text as="h3" sx={{ mb: 2, fontSize: 2 }}>Margins</Text>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 3,
              }}>
                <FormControl>
                  <FormControl.Label>Top (mm)</FormControl.Label>
                  <TextInput
                    type="number"
                    value={config.margine_top}
                    onChange={(e) => setConfig(prev => ({
                      ...prev,
                      margine_top: parseInt(e.target.value)
                    }))}
                    step={1}
                    min={0}
                    sx={{
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        opacity: 1,
                        height: '100%',
                      }
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormControl.Label>Bottom (mm)</FormControl.Label>
                  <TextInput
                    type="number"
                    value={config.margine_bottom}
                    onChange={(e) => setConfig(prev => ({
                      ...prev,
                      margine_bottom: parseInt(e.target.value)
                    }))}
                    step={1}
                    min={0}
                    sx={{
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        opacity: 1,
                        height: '100%',
                      }
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormControl.Label>Left (mm)</FormControl.Label>
                  <TextInput
                    type="number"
                    value={config.margine_left}
                    onChange={(e) => setConfig(prev => ({
                      ...prev,
                      margine_left: parseInt(e.target.value)
                    }))}
                    step={1}
                    min={0}
                    sx={{
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        opacity: 1,
                        height: '100%',
                      }
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormControl.Label>Right (mm)</FormControl.Label>
                  <TextInput
                    type="number"
                    value={config.margine_right}
                    onChange={(e) => setConfig(prev => ({
                      ...prev,
                      margine_right: parseInt(e.target.value)
                    }))}
                    step={1}
                    min={0}
                    sx={{
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        opacity: 1,
                        height: '100%',
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            {/* Paper Settings Section */}
            <Box>
              <Text as="h3" sx={{ mb: 2, fontSize: 2 }}>Paper Settings</Text>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 3,
                maxWidth: '100%'
              }}>
                <FormControl>
                  <FormControl.Label>Size</FormControl.Label>
                  <ActionMenu>
                    <ActionMenu.Button sx={{ width: '100%' }}>
                      {config.paper_size}
                    </ActionMenu.Button>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A1' }))}>
                          A1
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A2' }))}>
                          A2
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A3' }))}>
                          A3
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A4' }))}>
                          A4
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A5' }))}>
                          A5
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'A6' }))}>
                          A6
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'Letter' }))}>
                          Letter
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'Legal' }))}>
                          Legal
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'Tabloid' }))}>
                          Tabloid
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, paper_size: 'Ledger' }))}>
                          Ledger
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </FormControl>

                <FormControl>
                  <FormControl.Label>Orientation</FormControl.Label>
                  <ActionMenu>
                    <ActionMenu.Button sx={{ width: '100%' }}>
                      {config.orientation}
                    </ActionMenu.Button>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, orientation: 'Portrait' }))}>
                          Portrait
                        </ActionList.Item>
                        <ActionList.Item onSelect={() => setConfig(prev => ({ ...prev, orientation: 'Landscape' }))}>
                          Landscape
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </FormControl>
              </Box>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden'
      }}>
        {/* Navigation and Controls */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'border.default',
          px: 3,
          gap: 3,
          height: '48px',
        }}>
          {/* Back Button */}
          <Button
            onClick={() => navigate('/dashboard/templates')}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{ color: 'fg.muted' }}
          >
            Back
          </Button>

          <UnderlineNav aria-label="Template Editor Navigation" sx={{ flex: 1 }}>
            <UnderlineNav.Item
              href="#editor"
              aria-current={activeTab === 'editor' ? 'page' : undefined}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setActiveTab('editor');
              }}
            >
              Template Editor
            </UnderlineNav.Item>
            <UnderlineNav.Item
              href="#mapping"
              aria-current={activeTab === 'mapping' ? 'page' : undefined}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setActiveTab('mapping');
              }}
            >
              Data Mapping
            </UnderlineNav.Item>
            <UnderlineNav.Item
              href="#settings" 
              aria-current={activeTab === 'settings' ? 'page' : undefined}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setActiveTab('settings');
              }}
            >
              Settings
            </UnderlineNav.Item>
          </UnderlineNav>

          {/* Template Name and Save Button */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}>
            <TextInput
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              sx={{ width: '300px' }}
              placeholder="Template Name"
            />
            {!showPreview && (
              <Button
                onClick={() => setShowPreview(true)}
                variant="invisible"
                leadingVisual={EyeIcon}
                aria-label="Show Preview"
              >
                Preview
              </Button>
            )}
            <Button
              onClick={handleSave}
              variant="primary"
              size="medium"
            >
              Save
            </Button>
          </Box>
        </Box>

        {/* Main Content Area */}
        <Box sx={{ 
          display: 'flex', 
          height: 'calc(100% - 104px)',
          width: '100%',
          overflow: 'hidden',
          p: '20px'
        }}>
          {/* Left Panel - Tab Content */}
          <Box sx={{ 
            flex: showPreview ? 1 : 'auto',
            width: showPreview ? 'auto' : '100%',
            height: '100%',
            overflow: 'hidden',
            borderRight: showPreview ? '1px solid' : 'none',
            borderColor: 'border.default',
          }}>
            {renderTabContent()}
          </Box>

          {/* Right Panel - Preview (Conditionally Visible) */}
          {showPreview && (
            <Box sx={{ 
              width: '50%',
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              pl: 3
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 3,
                gap: 2,
                flexDirection: 'column',
                width: '100%'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  width: '100%',
                  gap: 2
                }}>
                  <Text as="h2">Preview</Text>
                  <Box sx={{ flex: 1 }} />
                  <Button
                    onClick={handleTestTemplate}
                    variant="primary"
                    size="small"
                    disabled={isTestingTemplate}
                  >
                    {isTestingTemplate ? 'Testing...' : 'Test Template'}
                  </Button>
                  <Button
                    onClick={() => setShowPreview(false)}
                    variant="invisible"
                    leadingVisual={XIcon}
                    sx={{ color: 'fg.muted' }}
                    aria-label="Close Preview"
                  />
                </Box>

                {/* Test Result Banner */}
                {testResult && (
                  <Box
                    sx={{
                      width: '100%',
                      p: 2,
                      borderRadius: 2,
                      bg: testResult.success ? 'success.subtle' : 'danger.subtle',
                      color: testResult.success ? 'success.fg' : 'danger.fg',
                      border: '1px solid',
                      borderColor: testResult.success ? 'success.muted' : 'danger.muted',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Text sx={{ flex: 1 }}>{testResult.message}</Text>
                    {testResult.success && testResult.pdfUrl && (
                      <>
                        <Button
                          size="small"
                          onClick={() => window.open(testResult.pdfUrl, '_blank')}
                        >
                          View PDF
                        </Button>
                        <Button
                          size="small"
                          variant="invisible"
                          onClick={() => setTestResult(null)}
                          leadingVisual={XIcon}
                          sx={{ color: testResult.success ? 'success.fg' : 'danger.fg' }}
                          aria-label="Close banner"
                        />
                      </>
                    )}
                    {!testResult.success && (
                      <Button
                        size="small"
                        variant="invisible"
                        onClick={() => setTestResult(null)}
                        leadingVisual={XIcon}
                        sx={{ color: 'danger.fg' }}
                        aria-label="Close banner"
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box 
                sx={{ 
                  flex: 1,
                  backgroundColor: '#e0e0e0',
                  border: '1px solid', 
                  borderColor: 'border.default',
                  borderRadius: 2,
                  overflow: 'hidden'
                }}
              >
                <iframe
                  srcDoc={previewHTML}
                  style={{ 
                    width: '100%', 
                    height: '100%', 
                    border: 'none'
                  }}
                  title="Template Preview"
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* Variable Format Menu */}
        {menuPosition && selectedVariable && (
          <Box
            sx={{
              position: 'fixed',
              top: menuPosition.top,
              left: menuPosition.left,
              backgroundColor: 'canvas.default',
              borderRadius: 2,
              boxShadow: 'shadow.large',
              border: '1px solid',
              borderColor: 'border.default',
              zIndex: 9999,
              minWidth: 200,
              transform: 'translate(0, 20px)'
            }}
          >
            <ActionList>
              <ActionList.Item onSelect={() => applyVariableFormat('date')}>
                <ActionList.LeadingVisual>
                  <CalendarIcon />
                </ActionList.LeadingVisual>
                Format as Date
              </ActionList.Item>
              <ActionList.Item onSelect={() => applyVariableFormat('currency')}>
                <ActionList.LeadingVisual>
                  <TagIcon />
                </ActionList.LeadingVisual>
                Format as Currency
              </ActionList.Item>
              <ActionList.Item onSelect={() => applyVariableFormat('barcode')}>
                <ActionList.LeadingVisual>
                  <HashIcon />
                </ActionList.LeadingVisual>
                Generate Barcode
              </ActionList.Item>
              <ActionList.Item onSelect={() => applyVariableFormat('qrcode')}>
                <ActionList.LeadingVisual>
                  <DeviceCameraIcon />
                </ActionList.LeadingVisual>
                Generate QR Code
              </ActionList.Item>
            </ActionList>
          </Box>
        )}

        {/* Variable Insert Modal */}
        {isVariableModalOpen && (
          <Dialog 
            title="Add Template Variable"
            onClose={() => setIsVariableModalOpen(false)}
            width="xlarge"
            footerButtons={[
              {
                content: 'Cancel',
                onClick: () => setIsVariableModalOpen(false)
              },
              {
                content: 'Insert',
                onClick: () => {
                  if (newVariable && editorRef.current) {
                    const position = editorRef.current.getCursorPosition();
                    editorRef.current.session.insert(position, `{{${newVariable}}}`);
                    setNewVariable('');
                    setIsVariableModalOpen(false);
                  }
                },
                variant: 'primary',
                disabled: !newVariable.trim(),
              }
            ]}
          >
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
              <FormControl>
                <FormControl.Label>Variable Name</FormControl.Label>
                <TextInput
                  value={newVariable}
                  onChange={(e) => setNewVariable(e.target.value)}
                  placeholder="Enter variable name"
                  block
                />
              </FormControl>

              <Box>
                <FormControl.Label>Mapped Variables</FormControl.Label>
                <Box 
                  sx={{ 
                    maxHeight: '200px',
                    overflow: 'auto',
                    border: '1px solid',
                    borderColor: 'border.default',
                    borderRadius: 2,
                    p: 2
                  }}
                >
                  {variables.length > 0 ? (
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 2 
                    }}>
                      {variables.map((variable, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            if (editorRef.current) {
                              const position = editorRef.current.getCursorPosition();
                              editorRef.current.session.insert(position, `{{${variable}}}`);
                              setIsVariableModalOpen(false);
                            }
                          }}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            px: 2,
                            py: 1,
                            borderRadius: '2em',
                            bg: 'accent.subtle',
                            color: 'accent.fg',
                            fontSize: 1,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            '&:hover': {
                              bg: 'accent.muted'
                            }
                          }}
                        >
                          {variable}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ 
                      py: 4,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'fg.muted'
                    }}>
                      No mapped variables available
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Dialog>
        )}

        {/* Toast notification - now visible regardless of active tab */}
        {showToast && (
          <Toast message="Template saved successfully!" />
        )}
      </Box>
    </DashboardLayout>
  );
} 