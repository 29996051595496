import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Box, Button, Text } from '@primer/react';
import { LogoTextShape, login_image } from '../assets';
import { getAuthErrorMessage } from '../utils/errorMessages';
import { ErrorAlert } from '../components/ErrorAlert';

export default function Login() {
  const { signInWithGoogle, user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await signInWithGoogle();
      navigate('/dashboard');
    } catch (error) {
      console.error("Login error:", error);
      setError(getAuthErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      bg: 'canvas.default'
    }}>
      {/* Left Panel */}
      <Box sx={{
        flex: '0 0 50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 6,
        gap: 5
      }}>
        <Box sx={{ mb: 4 }}>
          <img
            src={LogoTextShape}
            alt="Forge PDF"
            style={{
              height: '40px'
            }}
          />
        </Box>

        <Box sx={{
          maxWidth: '360px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box>
            <Text as="h1" sx={{ fontSize: 4, fontWeight: 'bold', mb: 2 }}>
              Automate your invoices and letters
            </Text>
            <Text sx={{ color: 'fg.muted' }}>
              Send your dynamic data to your crafted template and get the PDF
            </Text>
          </Box>

          {error && (
            <ErrorAlert
              message={error}
              onClose={() => setError(null)}
            />
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="default"
              onClick={handleGoogleSignIn}
              disabled={isLoading}
              sx={{
                width: '100%',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                border: '1px solid',
                borderColor: 'border.default',
                position: 'relative',
                paddingLeft: '48px' // Make space for the Google icon
              }}
            >
              <Box sx={{
                position: 'absolute',
                left: '16px',
                display: 'flex',
                alignItems: 'center'
              }}>
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  style={{ width: '18px', height: '18px' }}
                />
              </Box>
              {isLoading ? 'Signing in...' : 'Continue with Google'}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Right Panel */}
      <Box sx={{
        flex: '0 0 50%',
        bg: 'canvas.subtle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 6,
        borderLeft: '1px solid',
        borderColor: 'border.default'
      }}>
        <img
          src={login_image}
          alt="Login illustration"
          style={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </Box>
    </Box>
  );
}