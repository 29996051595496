interface FirebaseError {
  code?: string;
  message?: string;
}

export function getAuthErrorMessage(error: unknown): string {
  const firebaseError = error as FirebaseError;
  
  switch (firebaseError.code) {
    case 'auth/popup-blocked':
      return 'Popup was blocked by your browser. Please allow popups and try again.';
    case 'auth/cancelled-popup-request':
      return 'Sign-in was cancelled. Please try again.';
    case 'auth/unauthorized-domain':
      return 'This domain is not authorized for authentication. Please contact support.';
    case 'auth/popup-closed-by-user':
      return 'Sign-in window was closed. Please try again.';
    default:
      return 'An error occurred during sign in. Please try again.';
  }
}