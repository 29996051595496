import { Box, Text, FormControl, TextInput, Button, Flash } from '@primer/react';
import { DashboardLayout } from '../components/DashboardLayout';
import { useAuth } from '../contexts/AuthContext';
import { APP_VERSION } from '../config/version';
import { useState } from 'react';

export default function Settings() {
  const { user } = useAuth();
  const [saved, setSaved] = useState(false);

  const handleSave = () => {
    setSaved(true);
    setTimeout(() => setSaved(false), 3000);
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: 4 }}>
        <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold', mb: 4 }}>
          Settings
        </Text>

        {saved && (
          <Flash variant="success" sx={{ mb: 3 }}>
            Settings saved successfully!
          </Flash>
        )}

        <Box sx={{ 
          maxWidth: '600px',
          border: '1px solid',
          borderColor: 'border.default',
          borderRadius: 2,
          p: 4
        }}>
          <FormControl sx={{ mb: 3 }}>
            <FormControl.Label>Display Name</FormControl.Label>
            <TextInput 
              value={user?.displayName || ''} 
              placeholder="Enter your display name"
              disabled
            />
          </FormControl>

          <FormControl sx={{ mb: 3 }}>
            <FormControl.Label>Email</FormControl.Label>
            <TextInput 
              value={user?.email || ''} 
              placeholder="Enter your email"
              disabled
            />
          </FormControl>

          <FormControl sx={{ mb: 4 }}>
            <FormControl.Label>API Base URL</FormControl.Label>
            <TextInput 
              defaultValue="https://api.forgepdf.com" 
              placeholder="Enter API base URL"
            />
            <FormControl.Caption>
              The base URL for API requests
            </FormControl.Caption>
          </FormControl>

          <Button onClick={handleSave}>Save Changes</Button>
        </Box>

        {/* Version Info */}
        <Box sx={{ mt: 6, color: 'fg.muted' }}>
          <Text sx={{ fontSize: 1 }}>
            Version: {APP_VERSION}
          </Text>
        </Box>
      </Box>
    </DashboardLayout>
  );
} 