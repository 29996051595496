export const extractVariablesFromJson = (jsonData: any, prefix: string = ''): string[] => {
  let paths: string[] = [];
  
  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const value = jsonData[key];
      const newPath = prefix ? `${prefix}.${key}` : key;
      
      // Add the current path
      paths.push(newPath);
      
      // If value is an object and not null, recurse
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        paths = paths.concat(extractVariablesFromJson(value, newPath));
      }
    }
  }
  
  return paths;
}; 