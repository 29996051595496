export const sampleInvoice = `<!DOCTYPE html><html><head><meta charset="utf-8" />
<title>A simple, clean, and responsive HTML invoice template</title>
<style>
.invoice-box { max-width: 800px; margin: auto; padding: 30px; border: 1px solid #eee; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); font-size: 16px; line-height: 24px; font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; color: #555; }
.invoice-box table { width: 100%; line-height: inherit; text-align: left; }
.invoice-box table td { padding: 5px; vertical-align: top; }
.invoice-box table tr td:nth-child(2) { text-align: right; }
.invoice-box table tr.top table td { padding-bottom: 20px; }
.invoice-box table tr.top table td.title { font-size: 45px; line-height: 45px; color: #333; }
.invoice-box table tr.information table td { padding-bottom: 40px; }
.invoice-box table tr.heading td { background: #eee; border-bottom: 1px solid #ddd; font-weight: bold; }
.invoice-box table tr.details td { padding-bottom: 20px; }
.invoice-box table tr.item td { border-bottom: 1px solid #eee; }
.invoice-box table tr.item.last td { border-bottom: none; }
.invoice-box table tr.total td:nth-child(2) { border-top: 2px solid #eee; font-weight: bold; }
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td { width: 100%; display: block; text-align: center; }
  .invoice-box table tr.information table td { width: 100%; display: block; text-align: center; }
}
.invoice-box.rtl { direction: rtl; font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; }
.invoice-box.rtl table { text-align: right; }
.invoice-box.rtl table tr td:nth-child(2) { text-align: left; }
</style></head>
<body><div class="invoice-box"><table cellpadding="0" cellspacing="0">
<tr class="top"><td colspan="2"><table><tr>
  <td class="title"><img src="https://sparksuite.github.io/simple-html-invoice-template/images/logo.png" style="width: 100%; max-width: 300px" /></td>
  <td>Invoice #: {{invoice_number}}<br />Created: {{date created_date}}<br />Due: {{date due_date}}<br />Status: {{status}}</td>
</tr></table></td></tr>
<tr class="information"><td colspan="2"><table><tr>
  <td>{{company_name}}<br />{{company_address}}<br />{{company_phone}}<br />{{company_email}}</td>
  <td>{{client.name}}<br />{{client.contact}}<br />{{client.email}}<br />{{client.address}}</td>
</tr></table></td></tr>
<tr class="heading"><td>Payment Method</td><td>Details</td></tr>
<tr class="details"><td>{{payment.method}}</td><td>{{payment.details}}</td></tr>
<tr class="heading"><td>Item</td><td>Price</td></tr>
<tr class="item"><td>{{items.0.description}}</td><td>{{items.0.price}}</td></tr>
<tr class="item"><td>{{items.1.description}}</td><td>{{items.1.price}}</td></tr>
<tr class="item last"><td>{{items.2.description}}</td><td>{{items.2.price}}</td></tr>
<tr class="total"><td></td><td>Subtotal: {{subtotal}}<br />Tax ({{tax_rate}}%): {{tax_amount}}<br />Total: {{total}}</td></tr>
</table></div></body></html>`;

export const sampleTemplateData = {
  company_name: "Sparksuite, Inc.",
  company_address: "12345 Sunny Road\nSunnyville, CA 12345",
  company_phone: "+1 (555) 123-4567",
  company_email: "contact@sparksuite.com",
  invoice_number: "INV-2024-001",
  created_date: "2024-01-01",
  due_date: "2024-02-01",
  status: "Unpaid",
  client: {
    name: "Acme Corp.",
    contact: "John Doe",
    email: "john@example.com",
    address: "789 Business Ave\nEnterprise City, BZ 54321"
  },
  payment: {
    method: "Check",
    details: "Check #1000"
  },
  items: [
    {
      description: "Website design",
      price: 300.00
    },
    {
      description: "Hosting (3 months)",
      price: 75.00
    },
    {
      description: "Domain name (1 year)",
      price: 10.00
    }
  ],
  subtotal: 385.00,
  tax_rate: 10,
  tax_amount: 38.50,
  total: 423.50
}; 