// Add type declarations
type FormatType = 'date' | 'currency' | 'barcode' | 'qrcode';

interface FormatMatch {
  format: FormatType;
  varName: string;
}

export const replaceVariablesWithValues = (content: string, jsonData: string): string => {
  try {
    const data = JSON.parse(jsonData);
    
    // Function to get nested object value using dot notation
    const getNestedValue = (obj: any, path: string): any => {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : undefined;
      }, obj);
    };

    // Replace all {{variable}} occurrences with their values
    return content.replace(/\{\{([^}]+)\}\}/g, (match, variable) => {
      // Remove any whitespace
      const cleanVariable = variable.trim();
      
      // Check if it's a formatted variable (date, currency, etc.)
      const formatMatch = cleanVariable.match(/^(date|currency|barcode|qrcode)\s+(.+)$/);
      
      if (formatMatch) {
        const [, format, varName] = formatMatch;
        const value = getNestedValue(data, varName);
        
        if (value !== undefined) {
          switch (format as FormatType) {
            case 'date':
              return new Date(value).toLocaleDateString();
            case 'currency':
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(value);
            case 'barcode':
            case 'qrcode':
              return value; // You might want to add actual barcode/QR code generation here
            default:
              return value;
          }
        }
      } else {
        const value = getNestedValue(data, cleanVariable);
        return value !== undefined ? value : match;
      }
      
      return match; // Keep original if no value found
    });
  } catch (error) {
    console.error('Error replacing variables:', error);
    return content;
  }
}; 