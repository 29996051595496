import { Box, Text } from '@primer/react';
import { CheckIcon } from '@primer/octicons-react';

interface ToastProps {
  message: string;
}

export function Toast({ message }: ToastProps) {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '24px',
        left: '24px',
        zIndex: 100,
        backgroundColor: 'success.emphasis',
        color: 'white',
        padding: '12px 16px',
        borderRadius: '6px',
        boxShadow: 'shadow.large',
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        animation: 'slideIn 0.2s ease-out'
      }}
    >
      <CheckIcon />
      <Text sx={{ color: 'white' }}>{message}</Text>
    </Box>
  );
} 