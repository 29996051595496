import { Box, ActionList, Text } from '@primer/react';
import { 
  HomeIcon, 
  FileIcon, 
  KeyIcon,
  PersonIcon,
  BookIcon,
  GraphIcon,
} from '@primer/octicons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_VERSION, APP_NAME } from '../config/version';

export function MainNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ 
      height: '100%',
      bg: 'canvas.subtle',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      {/* Top Navigation Items */}
      <Box>
        <Box sx={{ px: 3, py: 3 }}>
          <Text sx={{ 
            fontSize: 1, 
            fontWeight: 'bold', 
            color: 'fg.muted',
            textTransform: 'uppercase',
            letterSpacing: '0.05em'
          }}>
            Navigation
          </Text>
        </Box>
        <ActionList>
          <ActionList.Item
            onSelect={() => navigate('/dashboard')}
            active={location.pathname === '/dashboard'}
          >
            <ActionList.LeadingVisual>
              <HomeIcon />
            </ActionList.LeadingVisual>
            Home
          </ActionList.Item>
          <ActionList.Item
            onSelect={() => navigate('/dashboard/api-keys')}
            active={location.pathname === '/dashboard/api-keys'}
          >
            <ActionList.LeadingVisual>
              <KeyIcon />
            </ActionList.LeadingVisual>
            API Keys
          </ActionList.Item>
          <ActionList.Item
            onSelect={() => navigate('/dashboard/templates')}
            active={location.pathname.startsWith('/dashboard/templates')}
          >
            <ActionList.LeadingVisual>
              <FileIcon />
            </ActionList.LeadingVisual>
            Templates
          </ActionList.Item>
          <ActionList.Item
            onSelect={() => navigate('/dashboard/usage')}
            active={location.pathname === '/dashboard/usage'}
          >
            <ActionList.LeadingVisual>
              <GraphIcon />
            </ActionList.LeadingVisual>
            Usage & Billing
          </ActionList.Item>
        </ActionList>
      </Box>

      {/* Bottom Section */}
      <Box>
        <ActionList>
          <ActionList.Divider />
          <ActionList.Item
            onSelect={() => navigate('/dashboard/profile')}
            active={location.pathname === '/dashboard/profile'}
          >
            <ActionList.LeadingVisual>
              <PersonIcon />
            </ActionList.LeadingVisual>
            Profile Settings
          </ActionList.Item>
          <ActionList.Item
            onSelect={() => navigate('/api-documentation?section=generate-pdf')}
            active={location.pathname === '/api-documentation'}
          >
            <ActionList.LeadingVisual>
              <BookIcon />
            </ActionList.LeadingVisual>
            API Documentation
          </ActionList.Item>
          {location.pathname === '/api-documentation' && (
            <ActionList.Group sx={{ pl: 4 }}>
              <ActionList.Item
                onSelect={() => navigate('/api-documentation?section=generate-pdf')}
                active={location.search.includes('generate-pdf')}
              >
                Generate PDF File
              </ActionList.Item>
              <ActionList.Item
                onSelect={() => navigate('/api-documentation?section=generate-url')}
                active={location.search.includes('generate-url')}
              >
                Get Public PDF URL
              </ActionList.Item>
            </ActionList.Group>
          )}
        </ActionList>

        {/* Copyright and Version */}
        <Box sx={{ 
          px: 3, 
          py: 3,
          borderTop: '1px solid',
          borderColor: 'border.muted',
          mt: 2
        }}>
          <Text sx={{ 
            fontSize: 0, 
            color: 'fg.muted', 
            display: 'block',
            mb: 1
          }}>
            © 2024 {APP_NAME}
          </Text>
          <Text sx={{ 
            fontSize: 0, 
            color: 'fg.muted',
            display: 'block'
          }}>
            {APP_VERSION}
          </Text>
        </Box>
      </Box>
    </Box>
  );
} 