import { useState, useEffect } from 'react';
import { Button, Box, Text, ActionList, Flash, Tooltip, Spinner } from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { PlusIcon, TrashIcon, ListUnorderedIcon, TableIcon, CopyIcon, CopilotIcon } from '@primer/octicons-react';
import { collection, query, where, getDocs, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { DashboardLayout } from '../components/DashboardLayout';
import { Toast } from '../components/Toast';
import { useNavigate } from 'react-router-dom';
import { sampleInvoice } from '../templates/sampleInvoice';
import { sampleTemplateData } from '../templates/sampleInvoice';
import { extractVariablesFromJson } from '../utils/jsonUtils';
import { TemplateGridCard } from '../components/TemplateGridCard';
import { Timestamp } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';

interface Template {
  id: string;
  name: string;
  html: string;
  config: {
    margine_bottom: number;
    margine_left: number;
    margine_right: number;
    margine_top: number;
    orientation: string;
    paper_size: string;
    print_background: boolean;
  };
  api_key_ref: any;
  user_ref: any;
  created_at: Timestamp;
  updated_at: Timestamp | null;
}

type ViewMode = 'list' | 'grid';

export default function Templates() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [toastType, setToastType] = useState<'copy' | 'delete' | null>(null);
  const [copiedTemplateId, setCopiedTemplateId] = useState<string | null>(null);

  const loadTemplates = async () => {
    try {
      const userRef = doc(db, 'users', user!.uid);
      const q = query(
        collection(db, 'templates'),
        where('user_ref', '==', userRef)
      );
      const querySnapshot = await getDocs(q);
      const loadedTemplates = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Template[];
      setTemplates(loadedTemplates);
    } catch (err) {
      console.error('Error loading templates:', err);
      setError('Failed to load templates');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTemplates();
  }, [user]);

  const handleDeleteClick = (templateId: string) => {
    setDeleteTemplateId(templateId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!deleteTemplateId) return;
    
    try {
      await deleteDoc(doc(db, 'templates', deleteTemplateId));
      await loadTemplates();
      setIsDeleteDialogOpen(false);
      setDeleteTemplateId(null);
      setToastType('delete');
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setToastType(null);
      }, 3000);
    } catch (err) {
      console.error('Error deleting template:', err);
      setError('Failed to delete template');
    }
  };

  const handleTemplateClick = (template: Template) => {
    navigate(`/dashboard/templates/edit/${template.id}`);
  };

  const handleNewTemplate = async () => {
    try {
      setLoading(true);
      const userRef = doc(db, 'users', user!.uid);
      const now = Timestamp.now();
      
      // Extract variables from sample data
      const variables = extractVariablesFromJson(sampleTemplateData);
      
      // Create new template document
      const newTemplate = {
        name: 'New Template',
        html: sampleInvoice,
        data_json: JSON.stringify(sampleTemplateData, null, 2),
        variables: variables,
        config: {
          margine_bottom: 4,
          margine_left: 4,
          margine_right: 4,
          margine_top: 4,
          orientation: 'Landscape',
          paper_size: 'Letter',
          print_background: true
        },
        user_ref: userRef,
        created_at: now,
        updated_at: now
      };

      const docRef = await addDoc(collection(db, 'templates'), newTemplate);
      navigate(`/dashboard/templates/edit/${docRef.id}`);
    } catch (err) {
      console.error('Error creating new template:', err);
      setError('Failed to create new template');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: '20px' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3 
        }}>
          <Text as="h1" sx={{ fontSize: 3, fontWeight: 'bold' }}>Templates</Text>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ 
              display: 'flex',
              border: '1px solid',
              borderColor: 'border.default',
              borderRadius: 2,
              overflow: 'hidden'
            }}>
              <Button
                variant="invisible"
                onClick={() => setViewMode('list')}
                aria-pressed={viewMode === 'list'}
                sx={{
                  color: viewMode === 'list' ? 'fg.default' : 'fg.muted',
                  bg: viewMode === 'list' ? 'canvas.subtle' : 'transparent'
                }}
              >
                <ListUnorderedIcon />
              </Button>
              <Button
                variant="invisible"
                onClick={() => setViewMode('grid')}
                aria-pressed={viewMode === 'grid'}
                sx={{
                  color: viewMode === 'grid' ? 'fg.default' : 'fg.muted',
                  bg: viewMode === 'grid' ? 'canvas.subtle' : 'transparent'
                }}
              >
                <TableIcon />
              </Button>
            </Box>
            
            <Button 
              onClick={() => navigate('/dashboard/templates/prompt')}
              variant="default"
              leadingVisual={CopilotIcon}
            >
              New Template with AI
            </Button>
            
            <Button 
              onClick={handleNewTemplate} 
              variant="primary"
              leadingVisual={PlusIcon}
            >
              New Template
            </Button>
          </Box>
        </Box>

        {error && (
          <Flash variant="danger" sx={{ mb: 3 }}>
            {error}
          </Flash>
        )}

        <Box sx={{ 
          height: 'calc(100vh - 84px)',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center',
              flex: 1,
              gap: 3
            }}>
              <Spinner size="large" />
              <Text sx={{ color: 'fg.muted' }}>Loading templates...</Text>
            </Box>
          ) : templates.length === 0 ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}>
              <Text>No templates found. Create one to get started.</Text>
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'auto',
              width: '100%',
              py: '20px',
              pb: '80px'
            }}>
              {viewMode === 'list' ? (
                <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                  {templates.map((template) => (
                    <Box 
                      key={template.id}
                      onClick={() => handleTemplateClick(template)}
                      sx={{ 
                        p: 3,
                        bg: 'canvas.subtle',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        border: '1px solid',
                        borderColor: 'border.default',
                        mb: 2,
                        '&:hover': {
                          borderColor: 'accent.emphasis',
                          boxShadow: 'shadow.medium',
                        },
                        '&:last-child': {
                          mb: 0
                        }
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                          <Text
                            as="h3"
                            sx={{
                              fontSize: 2,
                              fontWeight: 'bold',
                              color: 'fg.default',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {template.name}
                          </Text>
                        </Box>
                        <Text
                          sx={{
                            fontSize: 0,
                            color: 'fg.muted'
                          }}
                        >
                          {template.updated_at ? 'Updated' : 'Created'} {formatDistanceToNow(template.updated_at?.toDate() || template.created_at.toDate())} ago
                        </Text>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip text="Copy template ID" direction="n">
                          <Button
                            variant="invisible"
                            size="small"
                            sx={{ 
                              padding: '4px',
                              '&:hover': {
                                bg: 'accent.subtle'
                              }
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(template.id);
                              setCopiedTemplateId(template.id);
                              setToastType('copy');
                              setShowToast(true);
                              setTimeout(() => {
                                setShowToast(false);
                                setCopiedTemplateId(null);
                                setToastType(null);
                              }, 3000);
                            }}
                            leadingVisual={CopyIcon}
                          />
                        </Tooltip>
                        <Tooltip text="Delete template" direction="n">
                          <Button
                            variant="invisible"
                            size="small"
                            sx={{ 
                              color: 'danger.fg',
                              padding: '4px',
                              '&:hover': {
                                bg: 'danger.subtle'
                              }
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(template.id);
                            }}
                            leadingVisual={TrashIcon}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: 4
                  }}
                >
                  {templates.map((template) => (
                    <TemplateGridCard
                      key={template.id}
                      template={template}
                      onClick={() => navigate(`/dashboard/templates/edit/${template.id}`)}
                      onDelete={(e, id) => {
                        e.stopPropagation();
                        handleDeleteClick(id);
                      }}
                      onCopyId={(e, id) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(id);
                        setCopiedTemplateId(id);
                        setToastType('copy');
                        setShowToast(true);
                        setTimeout(() => {
                          setShowToast(false);
                          setCopiedTemplateId(null);
                          setToastType(null);
                        }, 3000);
                      }}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {showToast && (
        <Toast message={
          toastType === 'delete' ? 
          "Template removed successfully" : 
          `ID Copied: ${copiedTemplateId}`
        } />
      )}

      {isDeleteDialogOpen && (
        <Dialog
          title="Delete Template"
          onClose={() => setIsDeleteDialogOpen(false)}
          width="medium"
          footerButtons={[
            {
              content: 'Cancel',
              onClick: () => setIsDeleteDialogOpen(false)
            },
            {
              content: 'Delete',
              onClick: confirmDelete,
              variant: 'danger'
            }
          ]}
        >
          <Text>Are you sure you want to delete this template? This action cannot be undone.</Text>
        </Dialog>
      )}
    </DashboardLayout>
  );
} 