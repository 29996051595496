import { Box, Text } from '@primer/react';
import { DashboardLayout } from '../components/DashboardLayout';
import { useAuth } from '../contexts/AuthContext';

export default function Dashboard() {
  const { user } = useAuth();

  return (
    <DashboardLayout>
      <Box sx={{ p: 4 }}>
        <Box sx={{ border: '1px solid', borderColor: 'border.default', borderRadius: 2, p: 4 }}>
          <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold', mb: 3 }}>
            Welcome, {user?.displayName}!
          </Text>
          <Text color="fg.muted">Your dashboard content goes here.</Text>
        </Box>
      </Box>
    </DashboardLayout>
  );
}