import { useState } from 'react';
import { Box, Text, Button, Textarea, FormControl, Spinner } from '@primer/react';
import { DashboardLayout } from '../components/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, PaperclipIcon, XIcon, ArrowUpIcon } from '@primer/octicons-react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../config/firebase';
import { collection, addDoc, doc, Timestamp } from 'firebase/firestore';
import OpenAI from 'openai';

interface AttachedFile {
  file: File;
  preview: string;
  base64: string;
}

type MessageRole = 'user' | 'assistant';

type MessageContent = {
  type: 'text';
  text: {
    value: string;
    annotations: any[];
  };
} | {
  type: 'image_file';
  image_file: {
    file_id: string;
  };
};

type MessageContentPart = {
  type: 'text';
  text: string;
} | {
  type: 'image_url';
  image_url: {
    url: string;
    detail: 'low' | 'high' | 'auto';
  };
};

const formatHTML = (html: string): string => {
  // Define indent level
  let formatted = '';
  let indent = 0;
  
  // Split html into array of tags
  const tags = html.split(/(<\/?[^>]+>)/g);
  
  // Process each tag
  tags.forEach(tag => {
    // Skip empty tags
    if (!tag.trim()) return;
    
    // Check if closing tag
    if (tag.startsWith('</')) {
      indent--;
      formatted += '  '.repeat(indent) + tag + '\n';
    }
    // Check if self-closing tag
    else if (tag.endsWith('/>')) {
      formatted += '  '.repeat(indent) + tag + '\n';
    }
    // Check if opening tag
    else if (tag.startsWith('<')) {
      formatted += '  '.repeat(indent) + tag + '\n';
      // Don't indent for inline elements
      if (!tag.startsWith('<br') && !tag.startsWith('<img') && !tag.startsWith('<input')) {
        indent++;
      }
    }
    // Text content
    else {
      formatted += '  '.repeat(indent) + tag.trim() + '\n';
    }
  });
  
  return formatted;
};

export default function TemplatePrompt() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [prompt, setPrompt] = useState('');
  const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const OPENAI_API_KEY = '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';
  const ASSISTANT_ID = 'asst_1LMeb7YL7loyYC3Fto8eDxi7';

  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  const suggestions = [
    'Generate an invoice',
    'Need a 2 page contract for outsourcing my mobile app development',
    'Need a A6 letter where i can have sender name and receiver'
  ];

  const handleFileAttach = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;

    const newFiles: AttachedFile[] = [];
    const totalFiles = attachedFiles.length + fileList.length;

    if (totalFiles > 5) {
      setError('Maximum 5 images allowed');
      return;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      
      if (!file.type.startsWith('image/')) {
        setError('Only image files are allowed');
        return;
      }

      if (file.size > 20 * 1024 * 1024) {
        setError('Image size must be less than 20MB');
        return;
      }

      try {
        const base64 = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              resolve(reader.result);
            }
          };
          reader.onerror = reject;
        });

        newFiles.push({
          file,
          preview: URL.createObjectURL(file),
          base64
        });
      } catch (error) {
        console.error('Error processing file:', error);
        setError('Failed to process image');
        return;
      }
    }

    setAttachedFiles(prev => [...prev, ...newFiles]);
    setError(null);
  };

  const generateTemplate = async () => {
    setLoading(true);
    setError(null);

    try {
      // Base prompt that strictly requests JSON-only output
      let templatePrompt = `Generate a template with the following requirements. Return ONLY a JSON object with no additional text or explanation. The JSON must follow this exact structure:
{
  "name": "Template Name",
  "html": "HTML content",
  "sampleData": {},
  "variables": []
}

Requirements: ${prompt}

IMPORTANT: Respond with the JSON object only. Do not include any explanatory text before or after the JSON.`;

      // Step 1: If there's an image, get its description using Chat Completions API
      if (attachedFiles.length > 0) {
        console.log('Getting image description using Chat Completions API...');
        
        const messages = [
          {
            role: "user" as const,
            content: [
              {
                type: "text" as const,
                text: "Analyze this image and describe its layout, structure, and design elements in detail. Focus on describing how the content is organized and styled."
              },
              {
                type: "image_url" as const,
                image_url: {
                  url: attachedFiles[0].base64
                }
              }
            ]
          }
        ];

        const imageAnalysisResponse = await openai.chat.completions.create({
          model: "gpt-4-turbo",
          messages,
          max_tokens: 300
        });

        const imageDescription = imageAnalysisResponse.choices[0]?.message?.content;
        console.log('Image description:', imageDescription);

        // Combine original prompt with image description, maintaining strict JSON-only requirement
        templatePrompt = `Generate a template with the following requirements. Return ONLY a JSON object with no additional text or explanation. The JSON must follow this exact structure:
{
  "name": "Template Name",
  "html": "HTML content",
  "sampleData": {},
  "variables": []
}

Requirements: ${prompt}

Layout Description: ${imageDescription}

IMPORTANT: Respond with the JSON object only. Do not include any explanatory text before or after the JSON. Do not include any markdown code blocks or other formatting.`;
      }

      // Step 2: Use assistant API to generate the template (text only)
      console.log('Using assistant to generate template...');
      const thread = await openai.beta.threads.create();
      console.log('Thread created:', thread.id);

      // Send text-only message to assistant
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: templatePrompt
      });
      console.log('Message created in thread');

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: ASSISTANT_ID
      });
      console.log('Run started:', run.id);

      // Poll for completion
      let response;
      while (true) {
        const runStatus = await openai.beta.threads.runs.retrieve(
          thread.id,
          run.id
        );
        console.log('Run status:', runStatus.status);

        if (runStatus.status === 'completed') {
          const messages = await openai.beta.threads.messages.list(thread.id);
          const content = messages.data[0].content[0];
          if ('text' in content) {
            response = content.text.value;
          }
          console.log('Response received:', response);
          break;
        } else if (runStatus.status === 'failed') {
          throw new Error('Assistant failed to process the request');
        }

        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      if (response) {
        console.log('Parsing response...');
        // Clean up the response text - remove markdown code blocks
        const cleanText = response.replace(/```json\n?|```/g, '').trim();
        console.log('Cleaned response:', cleanText);
        
        const responseData = JSON.parse(cleanText);
        console.log('Parsed response data:', responseData);

        // Format the HTML before saving
        responseData.html = formatHTML(responseData.html);
        
        console.log('Creating template document...');
        const userRef = doc(db, 'users', user!.uid);
        const now = Timestamp.now();
        
        const newTemplate = {
          name: responseData.name || 'New Template',
          html: responseData.html,
          data_json: JSON.stringify(responseData.sampleData, null, 2),
          variables: responseData.variables,
          config: {
            margine_bottom: 4,
            margine_left: 4,
            margine_right: 4,
            margine_top: 4,
            orientation: 'Portrait',
            paper_size: 'Letter',
            print_background: true
          },
          user_ref: userRef,
          created_at: now,
          updated_at: now
        };

        console.log('Saving template to database...');
        const docRef = await addDoc(collection(db, 'templates'), newTemplate);
        console.log('Template saved:', docRef.id);
        
        navigate(`/dashboard/templates/edit/${docRef.id}`);
      } else {
        throw new Error('No response received');
      }
    } catch (err) {
      console.error('Error in template generation:', err);
      setError(err instanceof Error ? err.message : 'Failed to generate template');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ 
        p: '20px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-start', 
          alignItems: 'center', 
          mb: 3,
          gap: 3
        }}>
          <Button
            onClick={() => navigate(-1)}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{
              color: 'fg.muted',
              p: '6px',
              '&:hover': { color: 'fg.default' }
            }}
          >
            Back
          </Button>
          <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold' }}>Create Template with AI</Text>
        </Box>

        <Box sx={{ 
          maxWidth: '800px',
          mx: 'auto',
          px: 3,
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Text
            as="h1"
            sx={{
              fontSize: 48,
              fontWeight: 'bold',
              mb: 3,
              textAlign: 'center',
              color: 'fg.default'
            }}
          >
            What do you need?
          </Text>

          <Box sx={{ 
            bg: 'canvas.default',
            p: 3,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'border.default'
          }}>
            {attachedFiles.length > 0 && (
              <Box sx={{ 
                mb: 2,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2
              }}>
                {attachedFiles.map((file, index) => (
                  <Box 
                    key={index}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      bg: 'canvas.subtle',
                      p: 2,
                      borderRadius: 2,
                      width: 'fit-content'
                    }}
                  >
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: '1px solid',
                        borderColor: 'border.default'
                      }}
                    >
                      <img
                        src={file.preview}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                    <Text>{file.file.name}</Text>
                    <Button
                      variant="invisible"
                      size="small"
                      onClick={() => {
                        setAttachedFiles(prev => prev.filter((_, i) => i !== index));
                      }}
                      sx={{ p: 1 }}
                    >
                      <XIcon />
                    </Button>
                  </Box>
                ))}
              </Box>
            )}

            <Box sx={{ position: 'relative', mb: 2 }}>
              <Textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Describe what you want to generate"
                rows={3}
                sx={{ 
                  width: '100%',
                  resize: 'none',
                  minHeight: 'unset'
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                as="label"
                htmlFor="file-input"
                variant="default"
                leadingVisual={PaperclipIcon}
                sx={{ cursor: 'pointer' }}
                disabled={loading || attachedFiles.length >= 5}
              >
                Attach Images ({attachedFiles.length}/5)
                <input
                  type="file"
                  id="file-input"
                  onChange={handleFileAttach}
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                />
              </Button>
              <Box sx={{ flex: 1 }} />
              <Button
                variant="primary"
                disabled={(!prompt.trim() && !attachedFiles.length) || loading}
                leadingVisual={loading ? Spinner : ArrowUpIcon}
                onClick={generateTemplate}
              >
                {loading ? 'Generating...' : 'Generate'}
              </Button>
            </Box>
          </Box>

          <Box sx={{ 
            mt: 3,
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            {suggestions.map((suggestion: string, index: number) => (
              <Button
                key={index}
                variant="invisible"
                sx={{
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  p: '8px 12px',
                  '&:hover': {
                    bg: 'canvas.subtle'
                  }
                }}
              >
                {suggestion} →
              </Button>
            ))}
          </Box>
        </Box>
      </Box>

      {error && (
        <Box sx={{ 
          mt: 3, 
          p: 2, 
          borderRadius: 2,
          bg: 'danger.subtle',
          color: 'danger.fg',
          fontSize: 1
        }}>
          {error}
        </Box>
      )}
    </DashboardLayout>
  );
} 