import { Flash, Button } from '@primer/react';
import { XIcon } from '@primer/octicons-react';

interface ErrorAlertProps {
  message: string;
  onClose: () => void;
}

export function ErrorAlert({ message, onClose }: ErrorAlertProps) {
  return (
    <Flash 
      variant="danger" 
      sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      {message}
      <Button
        onClick={onClose}
        variant="invisible"
        sx={{ p: 1, color: 'fg.muted' }}
        aria-label="Close"
      >
        <XIcon />
      </Button>
    </Flash>
  );
}