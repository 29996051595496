import { Box } from '@primer/react';
import { useAuth } from '../contexts/AuthContext';
import { MainNavigation } from './MainNavigation';
import { Header } from './Header';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const { user } = useAuth();

  return (
    <Box sx={{ 
      height: '100vh', 
      width: '100vw', 
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <Box sx={{ 
        display: 'flex', 
        flex: 1,
        height: 'calc(100vh - 64px)', // Subtract header height
        overflow: 'hidden'
      }}>
        {/* Navigation */}
        <Box sx={{ width: 256, borderRight: '1px solid', borderColor: 'border.default' }}>
          <MainNavigation />
        </Box>

        {/* Content */}
        <Box sx={{ 
          flex: 1,
          overflow: 'hidden'
        }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
} 