import { useState, useEffect } from 'react';
import { Box, Text, Button, Flash, Spinner, Dialog } from '@primer/react';
import { ArrowLeftIcon } from '@primer/octicons-react';
import { collection, query, where, getDocs, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { DashboardLayout } from '../components/DashboardLayout';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

interface ApiUsageLog {
  id: string;
  status: string;
  error: string;
  created_at: any;
  template_id: string;
  dynamic_values: any;
  api_key_ref: any;
}

export default function ApiUsage() {
  const { apiKeyId } = useParams();
  const navigate = useNavigate();
  const [logs, setLogs] = useState<ApiUsageLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLog, setSelectedLog] = useState<ApiUsageLog | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const loadLogs = async () => {
    try {
      const apiKeyRef = doc(db, 'api_keys', apiKeyId!);
      const q = query(
        collection(db, 'api_key_history'),
        where('api_key_ref', '==', apiKeyRef)
      );
      const querySnapshot = await getDocs(q);
      const loadedLogs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as ApiUsageLog[];
      setLogs(loadedLogs);
    } catch (err) {
      console.error('Error loading logs:', err);
      setError('Failed to load API usage logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLogs();
  }, [apiKeyId]);

  return (
    <DashboardLayout>
      <Box sx={{ p: '20px' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-start', 
          alignItems: 'center', 
          mb: 3,
          gap: 3
        }}>
          <Button
            onClick={() => navigate(-1)}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{
              color: 'fg.muted',
              p: '6px',
              '&:hover': { color: 'fg.default' }
            }}
          >
            Back
          </Button>
          <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold' }}>API Usage Logs</Text>
        </Box>

        {error && (
          <Flash variant="danger" sx={{ mb: 3 }}>
            {error}
          </Flash>
        )}

        <Box sx={{ 
          height: 'calc(100vh - 84px)',
          minHeight: '200px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center',
              flex: 1,
              gap: 3
            }}>
              <Spinner size="large" />
              <Text sx={{ color: 'fg.muted' }}>Loading API usage logs...</Text>
            </Box>
          ) : logs.length === 0 ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}>
              <Text>No usage logs found for this API key.</Text>
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'auto',
              width: '100%',
              py: '20px',
              pb: '80px'
            }}>
              <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                {logs.map((log) => (
                  <Box 
                    key={log.id}
                    onClick={() => {
                      setSelectedLog(log);
                      setIsDialogOpen(true);
                    }}
                    sx={{ 
                      p: 3,
                      bg: 'canvas.subtle',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      border: '1px solid',
                      borderColor: 'border.default',
                      mb: 2,
                      '&:hover': {
                        borderColor: 'accent.emphasis',
                        boxShadow: 'shadow.medium',
                      },
                      '&:last-child': {
                        mb: 0
                      }
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: 'bold',
                            color: 'fg.default',
                          }}
                        >
                          Template ID: {log.template_id}
                        </Text>
                        <Text
                          sx={{
                            fontSize: 1,
                            px: 2,
                            py: 1,
                            borderRadius: 2,
                            bg: log.status === 'Done' ? 'success.subtle' : 'danger.subtle',
                            color: log.status === 'Done' ? 'success.fg' : 'danger.fg',
                          }}
                        >
                          {log.status}
                        </Text>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Text
                          sx={{
                            fontSize: 0,
                            color: 'fg.muted'
                          }}
                        >
                          {formatDistanceToNow(log.created_at.toDate())} ago
                        </Text>
                        {log.error && (
                          <Text
                            sx={{
                              fontSize: 0,
                              color: 'danger.fg',
                              fontFamily: 'mono'
                            }}
                          >
                            Error: {log.error}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {isDialogOpen && selectedLog && (
        <Dialog
          title="API Usage Log Details"
          onClose={() => {
            setIsDialogOpen(false);
            setSelectedLog(null);
          }}
          width="xlarge"
        >
          <Box sx={{ 
            maxHeight: '70vh', 
            overflow: 'auto',
            width: '1200px',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            p: 3
          }}>
            {/* Main Info Section */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>Main Information</Text>
              <Box sx={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: 2 }}>
                <Text sx={{ color: 'fg.muted' }}>Status:</Text>
                <Text sx={{ 
                  color: selectedLog.status === 'Done' ? 'success.fg' : 'danger.fg',
                  fontWeight: 'bold'
                }}>
                  {selectedLog.status}
                </Text>

                <Text sx={{ color: 'fg.muted' }}>Error:</Text>
                <Text sx={{ 
                  color: 'danger.fg',
                  fontFamily: 'mono'
                }}>
                  {selectedLog.error || 'None'}
                </Text>

                <Text sx={{ color: 'fg.muted' }}>Created At:</Text>
                <Text>{selectedLog.created_at.toDate().toLocaleString()}</Text>

                <Text sx={{ color: 'fg.muted' }}>Template ID:</Text>
                <Text sx={{ fontFamily: 'mono' }}>{selectedLog.template_id}</Text>
              </Box>
            </Box>

            {/* Dynamic Values Section */}
            {selectedLog.dynamic_values && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>Dynamic Values</Text>
                <Box sx={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: 2 }}>
                  {selectedLog.dynamic_values.user && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>User:</Text>
                      <Text>{selectedLog.dynamic_values.user.display_name}</Text>
                    </>
                  )}
                  
                  {selectedLog.dynamic_values.transaction_type && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>Transaction Type:</Text>
                      <Text>{selectedLog.dynamic_values.transaction_type}</Text>
                    </>
                  )}

                  {selectedLog.dynamic_values.transaction_amount && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>Amount:</Text>
                      <Text>
                        {selectedLog.dynamic_values.transaction_amount} {selectedLog.dynamic_values.transaction_currency_code}
                      </Text>
                    </>
                  )}

                  {selectedLog.dynamic_values.payment_status && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>Payment Status:</Text>
                      <Text>{selectedLog.dynamic_values.payment_status}</Text>
                    </>
                  )}

                  {selectedLog.dynamic_values.payment_method && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>Payment Method:</Text>
                      <Text>{selectedLog.dynamic_values.payment_method}</Text>
                    </>
                  )}

                  {selectedLog.dynamic_values.track_code && (
                    <>
                      <Text sx={{ color: 'fg.muted' }}>Track Code:</Text>
                      <Text sx={{ fontFamily: 'mono' }}>{selectedLog.dynamic_values.track_code}</Text>
                    </>
                  )}
                </Box>
              </Box>
            )}

            {/* Show Raw Data Button */}
            <Box sx={{ mt: 3 }}>
              <Button
                onClick={() => {
                  const el = document.createElement('textarea');
                  el.value = JSON.stringify(selectedLog, null, 2);
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand('copy');
                  document.body.removeChild(el);
                }}
                size="small"
                sx={{ width: 'fit-content' }}
              >
                Copy Raw Data
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </DashboardLayout>
  );
} 